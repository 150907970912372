
$font-basic : "Spoqa Han Sans Neo", "Noto Sans KR", "Nanum Gothic", "MalgunGothic", Dotum, Lato, Roboto, Arial, sans-serif;
$font-monospace :"Spoqa Han Sans Neo", "Noto Sans KR", "Nanum Gothic", "MalgunGothic", Dotum, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$breakpoint-xxs: 320px;
$breakpoint-xs: 375px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$primary: #82CA4A;
$primary-light : #BDE1A0 ;
$primary-dark :#45900A;

$point:#FFC806;

$stock-up:#FF303F;
$stock-down:#008DDD;

$black-900 :#222222;
$black-500 :#6E7785;
$black-300 :#A3AEBE;
$black-200 :#C6CCD5;

$white : #FFF;

$bg-1:#F4F7FB;
$bar-1:#E1E5EB;

@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

html {
  font-family: $font-basic;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 34, 34, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: $font-basic !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $black-900;
  text-align: left;
  background-color: $white;
}

#root {
  min-width:320px;
  max-width: calc(552px + 48px);
  margin:0 auto;
}

.stock-up {color:$stock-up;}
.stock-down {color:$stock-down;}

.AppHeader {
  padding-left:0 !important;
  padding-right:0 !important;

  header {
    max-width:calc(600px - 48px) !important;
    right:auto !important;
    padding-top:8px;
    box-shadow:none !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    [aria-label='menuBasic'] {
      margin-bottom:9px;
    }
  }

  .css-f47ups-MuiPaper-root-MuiAppBar-root, //테스트 서버에서 붙는 클래스
  .css-8ue4v9 // 실서버에서 붙는 클래스 
  {
    padding-top:0;
    //Whenyouscrolledpadding-top:0 !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    [aria-label='menuBasic']{
      margin-bottom:0;
    }

    img.App-logo {
      height:30px;
      
    }
    &:after{
      // 하단 그림자 부분. 그라데이션으로 처리함
      content:'';
      width:100%;
      height:10px;
      background: rgb(0,0,0);
      background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
      display:block;
      position:absolute;
      bottom:-10px;
    }
  }
}

#header.sub {
  width:100%;
  header {
    box-shadow:none !important;
    .MuiToolbar-root {
      display:flex;
      width:100%;
      justify-content: space-between;
      * {
        color:$black-300;
      }
      a {
        &:active {
          background:none !important;
        }
      }
      
    }
      
    
  }
}

.logoWrap {
  a {
    display:flex;
    text-decoration: none;
    align-items: center;
    p {
      font-size:14px;
      color:$white;
      margin:0 0 0 8px;
      line-height:1.3;
      word-break: keep-all;
      @media screen and (max-width:$breakpoint-sm ) {
        font-size:12px;
      }
      @media screen and (max-width:$breakpoint-xs ) {
        br {
          display:none;
        }
        
      }
    }
  }
}

.MuiDrawer-docked {
  // SideMenu Opened
  
  
  .MuiDrawer-paper {
      width:320px !important;
    &:before {
      // Modal background
      content:'';
      display:block;
      height:100vh;
      width:100vw;
      background:$black-900;
      opacity:.6;
      overflow:hidden;
      position:fixed;
      z-index:9998 !important;
    }
    .drawer {
      background:$white !important;
      height:100vh;
      width:320px;
      z-index:9999 !important;
      overflow:hidden;
      &::-webkit-scrollbar {
        display:none;
      }
      -ms-overflow-style: none;  // IE and Edge
      scrollbar-width: none;  // Firefox
      display:flex;
      flex-direction: column;
      @media screen and (max-width:$breakpoint-xxs ) {
        width:300px !important;
      }

      .navHeader {
        position:relative;
        padding:22px 22px 6px;
        [aria-label='menuOpen'] {
          margin-bottom:9px;
        }
        [aria-label='menuClose'] {
          position:absolute;
          right:5px;
          top:5px;
          color:$black-300;
          font-size:30px !important;
        }
      }
      .css-maofoc-MuiPaper-root {
        // 검색창 클래스 따로 지정하고 싶은데 실패
        margin:0 22px 8px;
        border-color:$primary !important;
        button {
          padding:0;
        }
      }
      .navList {
        margin:0 22px;
        
        a {
          text-decoration:none;
          color:inherit;
          
        }
        li {
          padding:6px;
          border-bottom:1px solid $bar-1;
          span {
            font-size:16px;
            letter-spacing:-0.03em;
          }
          
          
        }
      }
      .navFooter {
        margin:auto 22px 0;
        border-top:1px solid $bar-1;
        font-size:.675rem;
        padding:16px 0;
        word-break:keep-all;
        color:$black-300;
        p {
          margin:0;
          &.company {
            margin-bottom:.2rem;
          }
        }
        ul {
          list-style:none;
          margin:0 0 16px;
          padding:0;
          li {
            display:inline-block;
            font-weight:bold;
            &:last-of-type:after {
              display:none !important;
            }
            &:after {
              content:'';
              display:inline-block;
              width:1px;
              height:9px;
              background:$black-300;
              opacity:.5;
              margin:0 5px -1px;
            }
          }
          a {
            text-decoration:none;
            color:$black-300;
            &:hover {
              color:$black-500;
            }
          }
        }
      }
      
    }

  }
}



.searchWrap {
  background:$primary;
  padding:20px 20px 20px;
  margin-bottom:38px;
  position:relative;
  &:after {
    content:'';
    display:block;
    position:absolute;
    right:0;
    bottom:-18px;
    //background:url("../public/icon/finapple_boy.png") no-repeat right bottom/101px;
    background:url("../public/icon/finapple_visual.png") no-repeat right bottom/101px;
    width:101px;
    height:182px;
    z-index:9990;
  }
  .MuiPaper-outlined {
    border-color:#45910A;
  }
  input[type=text] {
    height:100%;
    font-size:18px;
    color:$black-900;
    outline: none;
    &::placeholder {
      color:#91A0AE;
      opacity:1;
    }
  }
  .recentSearch {
    ul {
      display:flex;
      justify-content: center;
      margin:14px 0 0;
      padding:0 40px 0 0;
      list-style:none;
      @media screen and (max-width:$breakpoint-sm ) {
        justify-content: flex-start;
      }

      li {
        font-size:15px;
        color:$white;
        margin:0 5px;
      }
      a {
        font-size:15px;
        color:$white;
        text-decoration:underline;
        cursor:pointer;
        &:hover {
          opacity:.7;
        }
      }
    }
  }
}


.sectionHead {
  display:flex;
  justify-content: space-between;
  margin-bottom:14px;
  h4.sectionTitle {
    font-size:22px;
    font-weight:bold;
    letter-spacing:-0.02em;
    
    svg {
      vertical-align:middle;
    }
    a {
      text-decoration:none;
      color:inherit;
    }
  }
  a[class*=Icon] {
    padding:0;
  }
}


.sectionNews {
  padding:0 20px 38px;
  a {
    display:block;
    margin-bottom:12px;
    
    .MuiPaper-root {
      background:#F5F7FB;
      border-radius:4px;;
      -webkit-border-radius:4px;
      -moz-border-radius:4px;
      -ms-border-radius:4px;
      -o-border-radius:4px;
      margin-bottom:12px;
      box-shadow:none;
      button {
        display:flex ;
        align-items: stretch;
        justify-content:flex-start;
      }
      img {
        width:110px;
      }
      

      .MuiTypography-h5 {
        color:$black-900;
        font-size:15px;
      }
      .MuiTypography-body2 {
        font-size:12px;
        color:$black-300;
      }
      .itemDate {
        font-size:10px;
        color:$black-300;
      }
    }
    &:last-of-type.MuiPaper-root {
      margin-bottom:0;
    }
    
  }
}



.sectionToday {
  background:$bg-1;
  padding:38px 18px 36px;

  .MuiBox-root {
    background:none;
    .MuiPaper-root {
      box-shadow:none;
      background:$white;
      border-radius:4px; 
     -webkit-border-radius:4px  ;
     -moz-border-radius:4px  ;
     -ms-border-radius:4px  ;
     -o-border-radius:4px  ;
     
     overflow:hidden;
      table {
        margin:16px 14px 8px 14px;
        width: calc(100% - 32px);
        a {
          display:block;
        }
        tr {
          display:flex;
        }
        td {
          border:none;
          padding:0;
          vertical-align:top;
          &.title {
            width:49%;
            min-width:200px;
            padding:5px 0 5px 10px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            flex:1;
            strong {
              display:block;
            }
            small {
              display:block;
              color:$black-300;
              font-size:10px;
            }
          }
          &.price {
            padding-top:3px;
            margin-right:10px;
            color:$black-900;
            span {
              color:$black-900 !important;
            }
          }
          &.move {
            padding-top:5px;
            // margin-right:20px;
            font-size:11px;
            min-width:50px;
          }
        }
      }
    }
    
    .MuiTypography-h5 {
      font-size:16px;
      font-weight:bold;
      color:$black-900;
      letter-spacing:-0.02em;
    }
  }
  
  &.typeSub {
    padding-top:26px;
  }
  
}

.sectionRecom.typeSub {
  background:$white;
  padding:38px 18px 0;
  margin-bottom:24px;
  .MuiBox-root:not(.sectionHead) {
    background:$bg-1;
    border-radius:4px; 
     -webkit-border-radius:4px  ;
     -moz-border-radius:4px  ;
     -ms-border-radius:4px  ;
     -o-border-radius:4px  ;
    .MuiPaper-root {
      box-shadow:none;
      background:$bg-1;
      margin-bottom:20px;
      overflow:hidden;
      table {
        margin:16px 14px 8px 14px;
        width: calc(100% - 32px);
        a {
          display:block;
          
        }
        tr {
          width:100%;
          display:flex;
        }
        td {
          border:none;
          padding:0 0 12px;
          vertical-align:top;
          &.title {
            width:49%;
            min-width:200px;
            padding:5px 0 12px 10px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            flex:1;
            strong {
              display:block;
            }
            small {
              display:block;
              color:$black-300;
              font-size:10px;
            }
          }
          &.Price {
            padding-top:3px;
            margin-right:10px;
            color:$black-900;
            span {
              color:$black-900 !important;
            }
          }
          &.move {
            display:inline-block;
            padding-top:3px;
              margin-right:4px;
          }
        }
      }
    }
  }
}

.sectionMarket {
  // 지수
  background:$bg-1;
  padding:26px 18px 0px 24px;
  border-top: 5px solid #DCE2E9;
  display:flex;
  justify-content: space-between;
  .MuiPaper-root {
    background:none;
    border-left:1px solid $bar-1;
    padding:0 0 0 10px;
    width:calc(25% - 10px);
    &:first-of-type {
      padding-left:0;
      border:none;
    }
    .MuiCardContent-root {
      padding:0;
      .MuiTypography-h6 {
        font-size:12px;
      }
      .MuiTypography-h5 {
        font-size:14px;
        font {
          color:$black-900 !important;
          font-weight:bold;
        }
      }
      .MuiTypography-body1 {
        font-size:10px;
        opacity:.5;
      }
      // .title {

      // }
    }
  }
  &.typeSub {
    padding:16px 22px;
    border-bottom:0;
    .MuiPaper-root {
      border-top:none;
      border-right:none;
      border-bottom:none;
    }
    + .chart {
      border-bottom:5px solid $bar-1;
    }
  }
  
}

.sectionMap {
  // 상승하락지도
  background:$bg-1;
  padding:38px 20px;
  border-bottom: 5px solid #DCE2E9;
  .css-1rcy8qy-MuiPaper-root-MuiCard-root, // 테스트 서버
  .css-qlkhaf // 실 서버
   {
    background:transparent;
  }
}
.apexcharts-toolbar {
  display:none !important;
}

.sectionSector {
  background:$bg-1;
  padding:38px 20px;
  border-bottom: 5px solid #DCE2E9;
  &.typeSub {
    background:$white;
    padding-bottom:0;
    border-bottom:0;
    margin-bottom:0px;
    border-radius:4px;
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    -ms-border-radius:4px;
    -o-border-radius:4px;
    #chart {
      background:$bg-1;
    }
    .sectorIc {
      padding:8px;
      background:$bg-1;
      margin-top:-5px;
      border-radius:4px;
      -webkit-border-radius:4px;
      -moz-border-radius:4px;
      -ms-border-radius:4px;
      -o-border-radius:4px;
      gap:5px;
      .tab {
        width:25%;
        text-align:center;
        font-size:13px;
        [class*=Typography] {
          background:none;
          color:$black-300;
          padding:6px 0;
          
        }
        &.on [class*=Typography] {
          background:$black-300 !important;
          color:$white !important;
        }
      }
    }
  }
}

.sectionSectorFeature.typeSub {
  padding:38px 20px;
  .list {
    gap:8px;
    > [class*=Paper] {
      width: calc(33% - 5px);
      padding:14px;
      margin:0;
      background:$bg-1;
      [class*=sector] {
        font-size:13px;
        font-weight:bold;
        color:$black-300;
        margin-bottom:8px;
      }
      [class*=ticker] {
        font-size:15px;
        font-weight:bold;
      }
      [class*=price] {
        font-size:18px;
        font-weight:bold;
      }
      [class*=price2] {
        font-size:15px;
        font-weight:bold;
      }
    }
  }
}


.sectionFeature {
  // 인덱스 테마별 종목 리스트(2건씩)
  background:$bg-1;
  padding:38px 20px;
  border-bottom: 5px solid #DCE2E9;
  h4.sectionTitle {
    color:$black-500;
    font-size:18px;
    font-weight:bold;
    letter-spacing:-0.03em;
    margin-top:32px;
    margin-bottom:8px;
    &:first-of-type {
      margin-top:0;
    }
    a {
      color:$black-500;
      text-decoration:none;
    }
  }
  .MuiBox-root {
    padding:0;
    display:flex;
    justify-content: space-between;
    .MuiPaper-root {
      width:100%;
      .MuiCardContent-root {
        // padding:0;
        padding-bottom:12px;
      }
      a {
        // padding:16px;
        text-decoration: none;
        color:inherit;
        display:block;
        display:flex;
        gap:10px;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        position:relative;
        // 아래 애들이 a 안에 들어가는 것이 맞으나, 혹시 나중에 구조 변경시 어떨지 몰라서 밖으로 빼둠
      }
      div[class*=stockTitle] {
        // align-self:flex-start;
        flex:1;
        strong {
          font-size:16px;
          font-weight:bold;
          letter-spacing:-0.03em;
          line-height:1.2;
          display:block;
          margin-bottom:0;
          min-width:100px;
          width:calc(100% - 40px);// 종목명이 길어지더라도 아바타 부분과 겹치지 않도록
          @media screen and (max-width:$breakpoint-xxs ) {
            min-width:50px;
          }
        }
        small {
          display:block;
          font-size:10px;
          color:$black-300;
        }
      }
      div[class*=stockPrice] {
        flex:0;
        // 원래 얘네가 아래로 붙어야 하는데 뭔가 코드가 적용이 안되고 있음
        span {
          font-size:16px;
          font-weight:bold;
          display:inline-block;
          margin-right:5px;
        }
        small {
          font-size:10px;
        }
      }
      div[class*=Avatar] {
        flex:0;
        position:absolute;
        right:-6px;
        top:-6px;
      }
    }
  }
}

.sectionReport {
  background:$white;
  padding:38px 20px;
  border-bottom: 5px solid #DCE2E9;
  .MuiPaper-root {
    margin-bottom:16px;
    .MuiCardMedia-root {
      border-radius:4px  ;
      -webkit-border-radius:4px  ;
      -moz-border-radius:4px  ;
      -ms-border-radius:4px  ;
      -o-border-radius:4px  ;
      width:62px;
    }
    .MuiCardContent-root {
      padding:2px 0 0 16px;
      .MuiTypography-h5 {
        font-size:15px;
        line-height:1.2;
        word-break:keep-all;
      }
      .itemDate {
        color:$black-300;
        font-size:10px;
        margin-top:8px;
      }
    }
    
  }
  
}

.sectionVideo {
  background:$white;
  padding:38px 20px;
  border-bottom: 5px solid #DCE2E9;

}


footer {
  font-size:.675rem;
  background:#F5F7FB;
  padding:20px;
  word-break:keep-all;
  color:$black-300;
  p {
    margin:0;
    &.msg {
      margin-bottom:.6rem;
    }
    &.company {
      font-weight:bold;
      margin-bottom:.2rem;
    }
  }
  ul {
    display:inline-block;
    list-style:none;
    margin:0;
    padding:0;
    li {
      display:inline-block;
      &:last-of-type:after {
        display:none !important;
      }
      &:after {
        content:'';
        display:inline-block;
        width:1px;
        height:9px;
        background:$black-300;
        opacity:.5;
        margin:0 5px -1px;
      }
     
      
    }
    a {
      text-decoration:none;
      color:$black-300;
      &:hover {
        color:$black-500;
      }
    }
    &.serivceLinks {
      margin-bottom:.6rem;
      a {
        font-weight:bold;
      }
    }
    &.companyInfo {
      a {
        font-weight:normal;
      }
    }
  }
}


.stockHeader { // detailStock 종목 상세
  margin-top:0 !important;
  .MuiCardContent-root {
    padding:8px 16px 16px !important;
  }
  .MuiTypography-h5 {
    margin:0;
    padding:0;
    line-height:1;
  }
  .title {
    font-size:20px;
    font-weight:bold;
  }
  .ticker {
    font-size:14px;
    color:$black-300;
  }
  .price-present {
    font-size:30px;
    font-weight:900;
    color:$black-900;
    margin-right:8px;
  }
  .industry {
    font-size:12px;
    color:$black-500;
  }
}
.stockGraph {
  background:$bg-1;  
  margin:0 0 24px !important;
  .MuiPaper-root {
    background:none;
  }
}

.stockTitle {
  margin:0 16px 8px !important;
  font-size:18px;
  font-weight:bold;
  color:$primary;
  letter-spacing:-0.03em;
}

.stockData {
  margin:0 16px 32px !important;
  padding-bottom:8px;
  border-bottom: 5px solid #F4F7FB;
  .list {
    display:flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap:wrap;
    margin:-14px 16px 16px;
    .MuiCard-root {
      width:25%;
      border-bottom:1px solid $bar-1;
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12){
          border:none;
        }
      .MuiCardContent-root {
        padding:14px 0 14px;
        
        h6 {
          font-size:12px;
          color:$black-300;
          font-weight:normal !important;
          letter-spacing:-0.03em;
        }
        p {
          font-size:15px;
          color:$black-900;
          font-weight:bold;
        }
      }
      
    }
  }
}

.stockVchart { //V-차트 하위 탭
  .tabMenu {
    border-bottom:1px solid $bar-1;
    display:flex;
    justify-content: space-between;
    .MuiBox-root {
      white-space: nowrap;
      width:auto;
      flex:1;
      .MuiPaper-root {
        padding:5px 0;
      }
      &.on .MuiPaper-root {
        color:$black-900;
        font-weight:bold;
        border-bottom:3px solid $primary;
      }
    } 
    .on {
      .MuiTypography-tab {
        cursor: pointer;
      }
      
    }
  }
  .list {
    background:#F4F7FB;
    .radioSection {
      width:calc(100% - 32px);
      padding-top:16px;
      label {
        font-size:12px;
        color:$black-500;
        margin-left:8px;
        span[class*=Radio] {
          padding:6px 0;
          input:not(:checked) + span {
            svg {
              color:$black-300;
            }
          }
          
        }
      }
    }
    .detail {
      margin:0 16px;
      width:calc(100% - 32px);
      padding-bottom:16px;
      border-bottom:1px solid $bar-1;
      .stockTitle {
        color:$black-900;
        margin:0 16px 0 0 !important;
      }
      .MuiPaper-root {
        background:none;
        .MuiCardContent-root {
          padding:3px 16px 0;
          border-left:1px solid $bar-1;
        }
        h6 {
          color:$black-300;
          font-size:12px;
          font-weight:normal;
        }
        p {
          color:$black-900;
          font-size:15px;
          font-weight:bold;
        }
      }
    }
    .chart {
      border-bottom:3px solid $bar-1;
      margin-bottom:24px;
      .MuiPaper-root {
        background:none !important;
      }
      
    }
  }
}

.searchResult { // 검색 결과
  margin-bottom:40px;
  a {
    display:block;
  }
  tr {
    display:flex;
  }
  td {
    border-bottom:1px solid $bar-1 !important;
    &.title {
      width:70%;
     div[class*=h5] {
        font-size:18px;
        font-weight:bold;
      }
      div[class*=ticker] {
        font-size:12px;
        font-weight:bold;
        // color:$black-300;
        color:$primary
      }
      ul, li {
        padding:0;
        margin:0;
        letter-spacing:-0.03em;
        font-size:12px;
      }
      li {
        margin-top:2px;
        color:$black-500;
      }
    }
    &.industry {
      width:30%;
      font-size:12px;
      color:$black-300;
      display:flex;
      align-items: center;;
    }
  }
  
}

.boardBasic {
  .boardTitle {
    background:$bg-1;
    padding:24px 20px;
    margin-bottom:16px; 
    div[class*=category] {
      font-size:12px;
      font-weight:bold;
      color:$primary;
      float:left;
    }
    h3 {
      font-size:18px;
      font-weight:bold;
      float:none;
      clear:both;
      padding-top:6px;
    }
    div[class*=date] {
      font-size:12px;
      color:$black-300;
      float:right;
    }
  }
  .boardContent {
    margin:0 16px;
    p {
      font-size:15px;
      line-height:1.5;
      letter-spacing:-0.03em;
      &:last-of-type {
        img {
          display:block;
          margin:30px auto 0;
          width:150px;
        }
      }
    }
    
  }
  
}
.pageTitle { // 서브 상단 네비 타이틀
  width:calc(100% - 26px);
  position:absolute;
  text-align:center;
  z-index:-1;
  h2 {
    font-size:18px;
    font-weight:normal;
    color:$black-500;
  }
}

.miningList {
  // 종목 발굴
  .themeTitle {
    margin:13px 16px;

    h2 {
      color:$primary;
      font-weight:bold;
      font-size:18px;
      margin:10px 0 10px 4px;
    }
  }
  
  div[class*=Avatar] {
    flex:0;
    position:absolute;
    right:-6px;
    top:-6px;
  }

  .tabWrap { // 구형 탭. 신형 탭 완성시까지 남겨둠.
    
    margin:0;
    width: 100%;
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid $bar-1;
    overflow:scroll;
    &::-webkit-scrollbar {
      display:none;
    }
    -ms-overflow-style: none;  // IE and Edge
    scrollbar-width: none;  // Firefox
    .tabMenu {
      flex:1;
      width:auto;
      div[class*=tab] {
        display:block;
        font-weight:16px;
        padding:10px;
      }
      &.on {
        div[class*=tab] {
          display:block;
          border-bottom:3px solid $primary;
          font-weight:bold;
        }
      }
      div[class*=root] {
        background:none;
        white-space:nowrap;
      }
      
    }
  }
}

  .tabContentWrap {
    padding:16px;
    background:$bg-1;
    .tabContentItem {
      margin-bottom:8px;
      align-items:stretch !important;
      width:calc(50% - 8px);
      margin-right:8px;
      &:nth-child(even) {
        margin-right:0;
      }
      div[class*=Paper]{
        height:100%;
      }
      div[class*=Card] {
        height:100%;
        padding-bottom:6px;
      }
      a {
        // padding:16px;
        text-decoration: none;
        color:inherit;
        display:block;
        display:flex;
        gap:10px;
        height:100%;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        position:relative;
        // 아래 애들이 a 안에 들어가는 것이 맞으나, 혹시 나중에 구조 변경시 어떨지 몰라서 밖으로 빼둠
      }
      div[class*=stockTitle] {
        // align-self:flex-start;
        flex:1;
        strong {
          font-size:16px;
          font-weight:bold;
          letter-spacing:-0.03em;
          line-height:1.2;
          display:block;
          margin-bottom:0;
          min-width:100px;
          // width:calc(100% - 40px);// 종목명이 길어지더라도 아바타 부분과 겹치지 않도록
          @media screen and (max-width:$breakpoint-xxs ) {
            min-width:50px;
          }
        }
        small {
          display:block;
          font-size:10px;
          color:$black-300;
        }
      }
      div[class*=stockPrice] {
        flex:0;
        // 원래 얘네가 아래로 붙어야 하는데 뭔가 코드가 적용이 안되고 있음
        span {
          font-size:16px;
          font-weight:bold;
          display:inline-block;
          margin-right:5px;
        }
        small {
          font-size:10px;
        }
      }
      

    }
  }

.tabWrapAuto { // 신형 탭 (화살표 포함), 높이+간격 조절해야 함
  border-bottom:1px solid $bar-1;
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
  a {
    font-size:14px;
    padding:0 6px;
  }
  .Mui-selected {
    font-weight:bold;
    color:$black-900;
  }
  .css-1anemkk-MuiTabs-indicator {
    height:3px;
  }
}


.tabMenuSub { // 서브탭
  background:$bg-1;
  padding:22px 16px 0;
  * {
    border-radius:4px !important;
    -webkit-border-radius:4px !important;
    -moz-border-radius:4px !important;
    -ms-border-radius:4px !important;
    -o-border-radius:4px !important;
  }
  .tabMenu1 {
    margin-right:3px;
    &:last-child {
      margin-right:0;
    }
    & > * {
      background:$black-200;
      color:$white;
      font-size:13px;
      padding:4px 6px;    
    }
    &.on {
      & > * {
        background:none;
      }
      background:$primary;
    }
  }

}